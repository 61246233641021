$(() => {
	const $slider = $('.js-slider');
	const $sliderList = $slider.find('.js-slider-list');
	$sliderList.slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		variableWidth: true,
		lazyLoad: 'ondemand',
		autoplay: true,
		autoplaySpeed: 4000,
		dots: true,
		arrows: false,
	});


	const $partner = $('.js-partner');
	const $partnerList = $partner.find('.js-partner-list');
	$partnerList.slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		variableWidth: true,
		lazyLoad: 'ondemand',
		dots: false,
		arrows: true,
		prevArrow: $partner.find('.js-partner-left'),
		nextArrow: $partner.find('.js-partner-right'),
		responsive: [
			{
				breakpoint: 960,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
				},
			},
		],
	});


	const $sidebar = $('.js-sidebar');
	$('.js-mobile-menu-btn').on('click', (e) => {
		e.preventDefault();
		$sidebar.toggleClass('sidebar-show');
	});
});
